<template>
  <div>
    <div>
      <homeComAbout></homeComAbout>
    </div>
    <div>
      666
    </div>
  </div>
</template>

<script>
import homeComAbout from "../homeComponents/homeComAbout.vue"
export default {
  name: 'homeComIntroduce',
  components: {
    homeComAbout
  }
}
</script>

<style>
</style>
